import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

interface CardProps {
  frontText: string
  backText: string
  showInfo: boolean
  frontTextClassName?: string
  backTextClassName?: string
}

export function Card({
  frontText,
  backText,
  showInfo,
  frontTextClassName,
  backTextClassName,
}: CardProps): ReactElement {
  return (
    <AnimatePresence mode="wait">
      {!showInfo && (
        <motion.span
          className={twMerge(
            'z-10 px-8 text-center font-realce text-[30px] leading-[30px] text-white desktop:text-[80px] desktop:leading-[70px]',
            frontTextClassName,
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15, ease: 'easeInOut' }}
          key={`front-${frontText}`}
        >
          {frontText}
        </motion.span>
      )}
      {showInfo && (
        <motion.span
          className={twMerge(
            'font-semi z-10 px-8 text-center font-matter text-[12px] font-semibold leading-4 text-white desktop:text-[30px] desktop:leading-[32px]',
            backTextClassName,
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15, ease: 'easeInOut' }}
          key={`front-${backText}`}
        >
          {backText}
        </motion.span>
      )}
    </AnimatePresence>
  )
}
